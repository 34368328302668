var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(this.isSubcategory ? "子類別" : "主類別")+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"fa-search","label":_vm.$t("common['Search']"),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.canEdit)?_c('span',{staticClass:"pt-5"},[_c('v-btn',{staticClass:"\n          font-weight-normal\n          text-capitalize\n          btn-primary\n          bg-gradient-secondary\n          py-3\n          px-6\n          ms-3\n        ",on:{"click":function($event){return _vm.onEditClick(null)}}},[_vm._v(" "+_vm._s(_vm.$t("common[\"Add\"]"))+" ")])],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableData,"loading":_vm.isLoading,"loading-text":_vm.$t("common['Loading... Please wait']"),"no-data-text":_vm.$t("common['No Data']"),"search":_vm.search,"sort-by":['id'],"sort-desc":[false],"footer-props":{
        'items-per-page-text': _vm.$t("common['Rows per page']"),
      },"page":_vm.pagination.page,"hide-default-footer":"","items-per-page":_vm.pagination.itemsPerPage},on:{"update:page":function($event){return _vm.$set(_vm.pagination, "page", $event)},"page-count":function($event){_vm.pagination.pageCount = $event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
      var item = ref.item;
return [(item.type === 'news')?_c('span',[_vm._v("最新消息")]):(item.type === 'campaigns')?_c('span',[_vm._v("熱門活動")]):(item.type === 'functions')?_c('span',[_vm._v("功能介紹")]):_vm._e()]}},{key:"item.createdAt",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.createdAt ? _vm.moment.unix(item.createdAt).format("YYYY/MM/DD HH:mm:ss") : ""))])]}},{key:"item.actions",fn:function(props){return [(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"dark":"","fab":"","x-small":"","color":"cyan"},on:{"click":function($event){return _vm.onEditClick(props.item)}}},on),[_c('v-icon',[_vm._v("fas fa-edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common['Edit']")))])]):_vm._e(),(_vm.canEdit)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"dark":"","fab":"","x-small":"","color":"pink darken-2"},on:{"click":function($event){return _vm.onDeleteClick(props.item)}}},on),[_c('v-icon',[_vm._v("fas fa-trash")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("common['Delete']")))])]):_vm._e()]}}])})],1),_c('v-card-actions',{staticClass:"card-padding"},[_c('Pagination',{attrs:{"pagination":_vm.pagination,"total":_vm.tableData.length,"loading":_vm.isLoading,"show-items-per-page":false}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }