<template>
  <v-card>
    <v-card-title>
      {{ this.isSubcategory ? "子類別" : "主類別" }}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fa-search"
        :label="$t(`common['Search']`)"
        single-line
        hide-details
      ></v-text-field>
      <span class="pt-5" v-if="canEdit">
        <v-btn
          class="
            font-weight-normal
            text-capitalize
            btn-primary
            bg-gradient-secondary
            py-3
            px-6
            ms-3
          "
          @click="onEditClick(null)"
        >
          {{ $t(`common["Add"]`) }}
        </v-btn>
      </span>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :loading="isLoading"
        :loading-text="$t(`common['Loading... Please wait']`)"
        :no-data-text="$t(`common['No Data']`)"
        :search="search"
        :sort-by="['id']"
        :sort-desc="[false]"
        :footer-props="{
          'items-per-page-text': $t(`common['Rows per page']`),
        }"
        :page.sync="pagination.page"
        hide-default-footer
        @page-count="pagination.pageCount = $event"
        :items-per-page="pagination.itemsPerPage"
      >
        <!-- <template v-slot:item.isPushNotification="{ item }">
                  <v-row class="mx-auto">
                    <v-switch
                      v-model="item.isPushNotification"
                      inset
                      color="green"
                      :false-value="0"
                      :true-value="1"
                      :disabled="true"
                    ></v-switch>
                  </v-row>
                </template> -->

        <template v-slot:item.type="{ item }">
          <span v-if="item.type === 'news'">最新消息</span>
          <span v-else-if="item.type === 'campaigns'">熱門活動</span>
          <span v-else-if="item.type === 'functions'">功能介紹</span>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <span>{{
            item.createdAt
              ? moment.unix(item.createdAt).format("YYYY/MM/DD HH:mm:ss")
              : ""
          }}</span>
        </template>

        <template v-slot:item.actions="props">
          <v-tooltip bottom v-if="canEdit">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2"
                dark
                fab
                x-small
                color="cyan"
                v-on="on"
                @click="onEditClick(props.item)"
              >
                <v-icon>fas fa-edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t(`common['Edit']`) }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="canEdit">
            <template v-slot:activator="{ on }">
              <v-btn
                class="mx-2"
                dark
                fab
                x-small
                color="pink darken-2"
                v-on="on"
                @click="onDeleteClick(props.item)"
              >
                <v-icon>fas fa-trash</v-icon>
              </v-btn>
            </template>
            <span>{{ $t(`common['Delete']`) }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions class="card-padding">
      <Pagination
        :pagination="pagination"
        :total="tableData.length"
        :loading="isLoading"
        :show-items-per-page="false"
      ></Pagination>
    </v-card-actions>
  </v-card>
</template>

<script>
import { formatNumber, checkPermission } from "src/util/utils";
import moment from "moment";
import { mapState } from "vuex";
import Pagination from "../../Campaigns/Widgets/Pagination.vue";

export default {
  name: "CategoryList",
  components: { Pagination },
  props: {
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    isSubcategory: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moment: moment,
      formatNumber: formatNumber,
      search: null,
      // tableHeaders: [
      //   {
      //     text: "ID",
      //     sortable: true,
      //     value: "categoryId",
      //   },
      //   {
      //     text: "分類",
      //     sortable: true,
      //     value: "type",
      //   },
      //   {
      //     text: "類別名稱",
      //     sortable: true,
      //     value: "categoryName",
      //   },
      //   // {
      //   //   text: "是否推播",
      //   //   sortable: true,
      //   //   value: "isPushNotification",
      //   // },
      //   {
      //     text: "建立時間",
      //     sortable: true,
      //     filterable: false,
      //     value: "createdAt",
      //   },
      //   { text: "", value: "actions", sortable: false },
      // ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
    };
  },
  computed: {
    ...mapState({
      permissions: (state) => state.userPermissions,
    }),
    canEdit() {
      if (this.permissions && this.permissions.length) {
        return checkPermission(
          this.permissions,
          "Admin:ArticleManage",
          "EditArticleCategories",
          true
        );
      }
      return false;
    },
    tableHeaders() {
      let headers = [
        {
          text: "ID",
          sortable: true,
          value: "categoryId",
        },
        // {
        //   text: "分類",
        //   sortable: true,
        //   value: "type",
        // },
        {
          text: "類別名稱",
          sortable: true,
          value: "categoryName",
        },
        // {
        //   text: "是否推播",
        //   sortable: true,
        //   value: "isPushNotification",
        // },
        {
          text: "建立時間",
          sortable: true,
          filterable: false,
          value: "createdAt",
        },
        { text: "", value: "actions", sortable: false },
      ];
      if (!this.isSubcategory) {
        headers.splice(1, 0, {
          text: "分類",
          sortable: true,
          value: "type",
        });
      }
      return headers;
    },
  },
  methods: {
    onEditClick(item) {
      this.$emit("edit", this.isSubcategory, item);
    },
    onDeleteClick(item) {
      this.$emit("delete", this.isSubcategory, item);
    },
  },
};
</script>
