<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12" md="6">
          <CategoryList
            :table-data="tableData"
            :is-subcategory="false"
            :is-loading="!isCategoryLoaded"
            @edit="onEditItem"
            @delete="onDeleteArticleCategoryClick"
          ></CategoryList>
        </v-col>
        <v-col cols="12" md="6">
          <CategoryList
            :table-data="subCategories"
            :is-subcategory="true"
            :is-loading="!isSubCategoryLoaded"
            @edit="onEditItem"
            @delete="onDeleteArticleCategoryClick"
          ></CategoryList>
        </v-col>
      </v-row>

      <v-dialog v-model="openEditDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="text-h5">
              {{ isNew ? $t(`common["Add"]`) : $t(`common["Edit"]`) }}
            </span>
            <span v-if="isSubcategory" class="text-h5"> 文章子類別 </span>
            <span v-else class="text-h5"> 文章主類別 </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="editForm" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editItem.categoryName"
                      label="類別名稱"
                      :rules="rules.required"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="!isSubcategory">
                    <v-radio-group
                      v-model="editItem.type"
                      row
                      mandatory
                      class="large-radio"
                    >
                      <v-radio
                        v-for="(type, index) in displayTypes"
                        :key="index"
                        :label="type.text"
                        :value="type.value"
                        :color="editItem.type === type.value ? '#1867c0' : ''"
                      ></v-radio>
                      <!-- <v-radio
                        label="熱門活動"
                        value="campaigns"
                        :color="editItem.type === 'campaigns' ? '#1867c0' : ''"
                      ></v-radio>
                      <v-radio
                        label="最新消息"
                        value="news"
                        :color="editItem.type === 'news' ? '#1867c0' : ''"
                      ></v-radio>
                      <v-radio
                        label="功能介紹"
                        value="functions"
                        :color="editItem.type === 'news' ? '#1867c0' : ''"
                      ></v-radio> -->
                    </v-radio-group>
                  </v-col>
                  <!-- <v-col cols="12" sm="6">
                    <v-switch
                      v-model="editItem.isPushNotification"
                      inset
                      color="green"
                      :false-value="0"
                      :true-value="1"
                      label="是否推播"
                    ></v-switch>
                  </v-col> -->
                </v-row>
              </v-form>
            </v-container>
            <!-- <small>*indicates required field</small> -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t(`common['Close']`) }}
            </v-btn>
            <v-btn color="blue darken-1" text @click="onSaveEditItem">
              {{ $t(`common['Save']`) }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";

import {
  fetchArticleCategories,
  fetchArticleSubCategories,
} from "src/apis/fetchData";
import {
  updateArticleCategory,
  updateArticleSubCategory,
} from "src/apis/updateData";
import {
  removeArticleCategory,
  removeArticleSubCategory,
} from "src/apis/deleteData";
import CategoryList from "./Widgets/CategoryList.vue";

export default {
  name: "ArticleCategories",
  components: { CategoryList },
  data() {
    return {
      moment: moment,
      isLoaded: true,
      isNew: false,
      openEditDialog: false,
      isCategoryLoaded: true,
      isSubCategoryLoaded: true,
      selectedCategoryId: null,
      tableData: [],
      tableHeaders: [
        {
          text: "ID",
          sortable: true,
          value: "categoryId",
        },
        {
          text: "類別名稱",
          sortable: true,
          value: "categoryName",
        },
        // {
        //   text: "是否推播",
        //   sortable: true,
        //   value: "isPushNotification",
        // },
        {
          text: "建立時間",
          sortable: true,
          filterable: false,
          value: "createdAt",
        },
        { text: "", value: "actions", sortable: false },
      ],
      subCategories: [],
      search: null,
      rules: {
        required: [(v) => !!v || this.$t(`common["Required"]`)],
      },
      defaultEditItem: {
        categoryName: null,
        isPushNotification: 1,
        type: "news",
      },
      editItem: {
        categoryName: null,
        type: "news",
      },
      isSubcategory: false,
      displayTypes: [
        // {
        //   value: "campaigns",
        //   text: "熱門活動",
        // },
        {
          value: "news",
          text: "最新消息",
        },
        {
          value: "functions",
          text: "使用攻略",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.ssoUser,
      pointSettings: (state) => state.pointSettings,
      permissions: (state) => state.userPermissions,
    }),
    isActionDisabled() {
      return !(this.isCategoryLoaded && this.isSubCategoryLoaded);
    },
    merchantId() {
      return this.isLoggedIn ? this.ssoUser.user._json.groups.merchant : null;
    },
    organizationId() {
      return this.isLoggedIn
        ? this.ssoUser.user._json.groups.organization
        : null;
    },
  },
  watch: {},
  created() {
    this.unwatchIsLoggedIn = this.$watch("isLoggedIn", (newVal) => {
      if (newVal) {
        this.unwatchIsLoggedIn();
        this.getArticleCategories();
        this.getArticleSubCategories();
      }
    });
  },
  mounted() {
    if (this.isLoggedIn) {
      this.getArticleCategories();
      this.getArticleSubCategories();
    }
  },
  methods: {
    getArticleCategories() {
      this.isCategoryLoaded = false;
      fetchArticleCategories(this.merchantId, null)
        .then((res) => {
          console.log("fetchArticleCategories done", res);
          this.tableData = [...res.data];
        })
        .catch((e) => {
          console.log("fetchArticleCategories failed", e);
        })
        .finally(() => {
          this.isCategoryLoaded = true;
        });
    },
    getArticleSubCategories() {
      this.isSubCategoryLoaded = false;
      fetchArticleSubCategories(this.merchantId, null)
        .then((res) => {
          console.log("fetchArticleSubCategories done", res);
          this.subCategories = [...res.data];
        })
        .catch((e) => {
          console.log("fetchArticleSubCategories failed", e);
        })
        .finally(() => {
          this.isSubCategoryLoaded = true;
        });
    },
    closeEditDialog() {
      this.$refs.editForm.resetValidation();
      this.$refs.editForm.reset();
      this.openEditDialog = false;
    },
    onEditItem(isSubcategory, item) {
      this.isSubcategory = isSubcategory;
      if (item) {
        this.editItem = Object.assign({}, item);
        this.isNew = false;
      } else {
        this.editItem = Object.assign({}, this.defaultEditItem);
        this.isNew = true;
      }
      this.openEditDialog = true;
    },
    onSaveEditItem() {
      const valid = this.$refs.editForm.validate();
      let updateCategory = updateArticleCategory;
      let type = "文章主類別";
      if (this.isSubcategory) {
        updateCategory = updateArticleSubCategory;
        type = "文章子類別";
      }
      if (valid) {
        updateCategory(this.merchantId, this.editItem)
          .then((res) => {
            console.log("updateCategory done", res);
            if (this.isSubcategory) {
              this.getArticleSubCategories();
            } else {
              this.getArticleCategories();
            }
            this.openEditDialog = false;
            this.$refs.editForm.resetValidation();
            this.$refs.editForm.reset();
          })
          .catch((e) => {
            console.log("updateCategory failed", e);
            let errmsg = this.isNew ? `新增${type}失敗` : `更新${type}失敗`;
            if (e.response && e.response.data && e.response.data.message) {
              errmsg += "<br/>" + e.response.data.message;
            }
            this.showErrorAlert(errmsg);
          });
      } else {
        this.showErrorAlert(this.$i18n.t(`errors["Incorrect Fields"]`));
      }
    },
    onDeleteArticleCategoryClick(isSubcategory, item) {
      this.isSubcategory = isSubcategory;
      let type = "文章主類別";
      if (this.isSubcategory) {
        type = "文章子類別";
      }
      this.$swal({
        title: `刪除${type}`,
        html: `是否確定刪除${type} - <b>${item.categoryName}</b> ？<br/>刪除後將無法復原`,
        type: "info",
        showCancelButton: true,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        cancelButtonText: this.$i18n.t(`common["Cancel"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      }).then((res) => {
        console.log(res);
        if (res.isConfirmed) {
          this.deleteArticleCategory(item);
        }
      });
    },
    deleteArticleCategory(item) {
      let removeCategory = removeArticleCategory;
      let type = "文章主類別";
      if (this.isSubcategory) {
        removeCategory = removeArticleSubCategory;
        type = "文章子類別";
      }
      removeCategory(this.merchantId, item.categoryId)
        .then((res) => {
          console.log("removeArticle done: ", res);
          if (res) {
            if (this.isSubcategory) {
              this.getArticleSubCategories();
            } else {
              this.getArticleCategories();
            }
            this.$swal({
              title: "刪除成功",
              html: `已成功刪除${type} - <b>${item.categoryName}</b>`,
              type: "success",
              showCancelButton: false,
              confirmButtonText: this.$i18n.t(`common["Confirm"]`),
              customClass: {
                confirmButton: "btn bg-gradient-success",
                cancelButton: "btn bg-gradient-danger",
              },
            });
          } else {
            throw new Error("Error");
          }
        })
        .catch((e) => {
          console.log("removeArticle failed", e);
          let errmsg = "無法刪除文章";
          if (e.response && e.response.data && e.response.data.message) {
            errmsg += "<br/>" + e.response.data.message;
          }
          const title = "刪除失敗";
          this.showErrorAlert(errmsg, title);
        });
    },
    showErrorAlert(message, inputTitle) {
      let title = this.$i18n.t(`errors["Update"]`);
      if (this.isNew) {
        title = this.$i18n.t(`errors["Create"]`);
      }
      this.$swal({
        title: inputTitle || title,
        html: message,
        type: "error",
        showCancelButton: false,
        confirmButtonText: this.$i18n.t(`common["Confirm"]`),
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
      });
    },
  },
};
</script>
<style scoped>
.large-radio::v-deep i {
  font-size: 24px;
}
.large-radio::v-deep label {
  font-size: 16px;
  padding-left: 3px;
}
.large-radio::v-deep .v-radio {
  padding: 0px;
}
</style>
